<div class="parent flex-col-container">
    <div class="flex-grow"></div>
    <div class="flex-row-container">
        <div class="flex-grow"></div>
        <div style="margin: 10px">
            <img class="logo" src="../../../assets/img/logo-alpha.svg">
            <p>
                The Ultimate Cloud Automation Service for Discord.
            </p>
            <p>
                Create HTTP and time-based Triggers to post messages, change status, and much more.
            </p>
            <div class="flex-row-container">
                <div class="flex-grow"></div>
                <a href="/sign-in">Sign In</a>
                <a href="/register">Create Account</a>
                <div class="flex-grow"></div>
            </div>
        </div>
        <div class="flex-grow"></div>
    </div>
    <div class="flex-grow-2"></div>
</div>